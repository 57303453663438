// @flow
import * as React from 'react';
import type { Node, } from 'react';

import BoxyInverseLabelsItem from './BoxyInverseLabelsItem';
import BoxyMedia from '../Boxy/BoxyMedia';
import LayoutContainer from '../../../PageLayout/LayoutContainer';
import LayoutRow from '../../../PageLayout/LayoutRow';
import setBiAction from '../../../../utils/setBiAction';

import type { ImageDataType, } from '../../../../flowTypes/ImageDataType';
import type { HTMLEmbedDataType, } from '../../../../flowTypes/HTMLEmbedDataType';
import type { GalleryDataType, } from '../../../../flowTypes/GalleryDataType';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';

type MediaType = ImageDataType | HTMLEmbedDataType | GalleryDataType;

type Props = {
  list: ListDataType,
  isLazyloadImages: boolean,
  biAction: ?ListBiActionType,
};

export default function BoxyInverseLabelsView({
  list,
  isLazyloadImages,
  biAction,
}: Props): Node {
  const item: TeaserDataType = list.items[0];
  const media: ?MediaType = item ? item.media : null;
  return item && media && [ 'image', 'embed', 'infographic', ].includes(media.kind) ? (
    <LayoutRow
      bgc="transparent"
      miscStyles={{ marginTop: [ { until: 's', value: '2rem', }, { from: 's', value: '0', }, ], }}
      attrs={{
        'data-test': 'BoxyInverseLabels',
      }}
    >
      <LayoutContainer>
        <BoxyInverseLabelsItem item={item} wrapper={LayoutContainer} onClick={setBiAction(0, item, biAction)}>
          <BoxyMedia media={media} isLazyloadImages={isLazyloadImages} />
        </BoxyInverseLabelsItem>
      </LayoutContainer>
    </LayoutRow>
  ) : null;
}
